import React, { useState, useEffect } from "react"
import axios from "axios"
import { discounts } from "../models/discounts"

export function ShopifyClient(domain, storefrontAccessToken, apiVersion) {
  const cartQuery = `cart {
    id
    checkoutUrl
    lines(first:100) {
        edges {
            node {
                id
                attributes {
                    key
                    value
                }
                quantity
                merchandise {
                    ... on ProductVariant {
                        id
                        title
                        product {
                            title
                        }
                        image {
                            originalSrc
                        }
                        quantityAvailable
                        priceV2 {
                            amount
                        }
                        selectedOptions {
                            name
                            value
                        }
                    }
                }
                sellingPlanAllocation {
                    sellingPlan {
                        options {
                            name
                            value
                        }
                    }
                }
            }
        }
    }
    discountCodes{
        applicable
        code
    }
    buyerIdentity {
        email
        customer {
            id
        }
    }
    estimatedCost {
        totalAmount {
            amount
            currencyCode
        }
        subtotalAmount {
            amount
            currencyCode
        }
        totalTaxAmount {
            amount
            currencyCode
        }
        totalDutyAmount {
            amount
            currencyCode
        }
    }
}`

  // Cart Methods

  const createCart = cartInput => {
    let promise = new Promise(function (resolve, reject) {
      let query = `mutation createCart($cartInput: CartInput) {
              cartCreate(input: $cartInput) {
                  ${cartQuery}
              }
            }`

      axios({
        method: "post",
        url: `https://${domain}/api/${apiVersion}/graphql.json`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Shopify-Storefront-Access-Token": storefrontAccessToken,
        },
        data: {
          query: query,
          variables: {
            cartInput: cartInput,
          },
        },
      }).then(res => {
        if (res.data.data.cartCreate.cart.id) {
          resolve(res.data.data.cartCreate.cart)
        } else {
          reject(res.data.data.userErrors)
        }
      })
    })

    return promise
  }

  const addLineItems = (cartId, lineItemsToAdd) => {
    let promise = new Promise(function (resolve, reject) {
      let query = `mutation cartLinesAdd($cartId: ID!, $lines:[CartLineInput!]!) {
              cartLinesAdd(cartId: $cartId, lines: $lines) {
                  ${cartQuery}
              }
          }`

      axios({
        method: "post",
        url: `https://${domain}/api/${apiVersion}/graphql.json`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Shopify-Storefront-Access-Token": storefrontAccessToken,
        },
        data: {
          query: query,
          variables: {
            cartId: cartId,
            lines: lineItemsToAdd,
          },
        },
      }).then(res => {
        if (res.data.data.cartLinesAdd.cart.id) {
          resolve(res.data.data.cartLinesAdd.cart)
        } else {
          reject(res.data.errors)
        }
      })
    })
    return promise
  }

  const updateLineItems = (cartId, lineItemsToUpdate) => {
    let promise = new Promise(function (resolve, reject) {
      let query = `mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
              cartLinesUpdate(cartId: $cartId, lines: $lines) {
                  ${cartQuery}
              }
          }`

      axios({
        method: "post",
        url: `https://${domain}/api/${apiVersion}/graphql.json`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Shopify-Storefront-Access-Token": storefrontAccessToken,
        },
        data: {
          query: query,
          variables: {
            cartId: cartId,
            lines: lineItemsToUpdate,
          },
        },
      }).then(res => {
        if (res.data.data.cartLinesUpdate.cart) {
          resolve(res.data.data.cartLinesUpdate.cart)
        } else {
          reject(res.data.data.userErrors)
        }
      })
    })
    return promise
  }

  const removeLineItems = (cartId, lineIdsToRemove) => {
    let promise = new Promise(function (resolve, reject) {
      let query = `mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
              cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
                  ${cartQuery}
              }
          }`

      axios({
        method: "post",
        url: `https://${domain}/api/${apiVersion}/graphql.json`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Shopify-Storefront-Access-Token": storefrontAccessToken,
        },
        data: {
          query,
          variables: {
            cartId: cartId,
            lineIds: lineIdsToRemove,
          },
        },
      }).then(res => {
        if (res.data.data.cartLinesRemove.cart.id) {
          resolve(res.data.data.cartLinesRemove.cart)
        } else {
          reject(res.data.data.userErrors)
        }
      })
    })
    return promise
  }

  const updateBuyerIdentity = (cartId, customerAccessToken) => {
    let promise = new Promise(function (resolve, reject) {
      let query = `mutation cartBuyerIdentityUpdate($cartId: ID!, $buyerIdentity: CartBuyerIdentityInput!) {
              cartBuyerIdentityUpdate(cartId: $cartId, buyerIdentity: $buyerIdentity) {
                  ${cartQuery}
                  userErrors {
                      code
                      field
                      message
                  }
              }
            }`

      axios({
        method: "post",
        url: `https://${domain}/api/${apiVersion}/graphql.json`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Shopify-Storefront-Access-Token": storefrontAccessToken,
        },
        data: {
          query,
          variables: {
            cartId: cartId,
            buyerIdentity: {
              customerAccessToken: customerAccessToken,
            },
          },
        },
      }).then(res => {
        if (res.data.data.cartBuyerIdentityUpdate.cart.id) {
          resolve(res.data.data.cartBuyerIdentityUpdate.cart)
        } else {
          reject(res.data.data.userErrors)
        }
      })
    })
    return promise
  }

  const updateCartDiscountCodes = (cartId, discountCodes) => {
    let promise = new Promise(function (resolve, reject) {
      let query = `mutation cartDiscountCodesUpdate($cartId: ID!, $discountCodes: [String!]) {
              cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
                  ${cartQuery}
                  userErrors {
                      code
                      field
                      message
                  }
              }
            }`

      axios({
        method: "post",
        url: `https://${domain}/api/${apiVersion}/graphql.json`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Shopify-Storefront-Access-Token": storefrontAccessToken,
        },
        data: {
          query,
          variables: {
            cartId: cartId,
            discountCodes: discountCodes,
          },
        },
      }).then(res => {
        if (res.data.data.cartDiscountCodesUpdate.cart.id) {
          resolve(res.data.data.cartDiscountCodesUpdate.cart)
        } else {
          reject(res.data.data.userErrors)
        }
      })
    })
    return promise
  }

  // Customer Methods

  const getCustomer = async customerAccessToken => {
    let promise = new Promise(function (resolve, reject) {
      const getCustomerQuery = `query ($token: String!) {
        customer(customerAccessToken: $token) {
          id
          acceptsMarketing
          createdAt
          addresses(first: 10) {
            edges {
              node {
                id
                address1
                address2
                city
                company
                country
                countryCodeV2
                firstName
                lastName
                phone
                province
                provinceCode
                zip
              }
            }
          }
          defaultAddress {
            id
            address1
            address2
            city
            company
            country
            countryCodeV2
            firstName
            lastName
            phone
            province
            provinceCode
            zip
          }
          displayName
          firstName
          lastName
          email
          phone
          tags
          orders(first: 50) {
            edges {
              node {
                id
                orderNumber
                processedAt
                cancelReason
                canceledAt
                currencyCode
                currentSubtotalPrice {
                  amount
                  currencyCode
                }
                currentTotalPrice {
                  amount
                  currencyCode
                }
                currentTotalTax {
                  amount
                  currencyCode
                }
                totalShippingPrice {
                  amount
                  currencyCode
                }
                totalRefunded {
                  amount
                  currencyCode
                }
                shippingDiscountAllocations {
                  allocatedAmount {
                    amount
                    currencyCode
                  }
                }
                successfulFulfillments(first: 5) {
                  trackingCompany
                  trackingInfo {
                    number
                    url
                  }
                }
                customerLocale
                financialStatus
                fulfillmentStatus
                name
                shippingAddress {
                  address1
                  address2
                  city
                  company
                  country
                  countryCodeV2
                  firstName
                  lastName
                  phone
                  province
                  provinceCode
                  zip
                }
                totalRefunded {
                  amount
                  currencyCode
                }
                totalShippingPrice {
                  amount
                  currencyCode
                }
                lineItems(first: 50) {
                  edges {
                    node {
                      quantity
                      discountAllocations {
                        allocatedAmount {
                          amount
                          currencyCode
                        }
                        discountApplication {
                          allocationMethod
                          targetSelection
                          targetType
                          value {
                            ... on MoneyV2 {
                              amount
                            }
                          }
                        }
                      }
                      discountedTotalPrice {
                        amount
                        currencyCode
                      }
                      originalTotalPrice {
                        amount
                        currencyCode
                      }
                      title
                      variant {
                        image {
                          altText
                          url
                        }
                        selectedOptions {
                          name
                          value
                        }
                        product {
                          handle
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }`

      axios({
        method: "post",
        url: `https://${domain}/api/${apiVersion}/graphql.json`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Shopify-Storefront-Access-Token": storefrontAccessToken,
        },
        data: {
          query: getCustomerQuery,
          variables: {
            token: customerAccessToken,
          },
        },
      })
        .catch(e => reject(e))
        .then(res => {
          resolve(res)
        })
    })

    return promise
  }

  const createCustomer = async data => {
    let mark
    if (data.acceptsMarketing === "true") {
      mark = true
    } else {
      mark = false
    }

    let promise = new Promise(function (resolve, reject) {
      const createCustomerQuery = `mutation customerCreate($input: CustomerCreateInput!) {
              customerCreate(input: $input) {
              customerUserErrors {
                  code
                  field
                  message
              }
              customer {
                  id
              }
              }
          }`
      let input

      if (data.phone === "") {
        input = {
          email: data.email,
          password: data.password,
          acceptsMarketing: mark,
          firstName: data.firstName,
          lastName: data.lastName,
        }
      } else {
        input = {
          email: data.email,
          password: data.password,
          acceptsMarketing: mark,
          firstName: data.firstName,
          lastName: data.lastName,
          phone: data.phone,
        }
      }

      axios({
        method: "post",
        url: `https://${domain}/api/${apiVersion}/graphql.json`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Shopify-Storefront-Access-Token": storefrontAccessToken,
        },
        data: {
          query: createCustomerQuery,
          variables: {
            input: input,
          },
        },
      })
        .catch(e => reject(e))
        .then(res => {
          resolve(res.data)
        })
    })
    return promise
  }

  const getAccessToken = async (email, password) => {
    let promise = new Promise(function (resolve, reject) {
      const getAccessTokenQuery = `mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
              customerAccessTokenCreate(input: $input) {
                customerAccessToken {
                  accessToken
                  expiresAt
                }
                customerUserErrors {
                  code
                  field
                  message
                }
              }
          }`

      axios({
        method: "post",
        url: `https://${domain}/api/${apiVersion}/graphql.json`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Shopify-Storefront-Access-Token": storefrontAccessToken,
        },
        data: {
          query: getAccessTokenQuery,
          variables: {
            input: {
              email: email,
              password: password,
            },
          },
        },
      })
        .catch(e => reject(e))
        .then(async res => {
          let data
          if (
            res.data.data.customerAccessTokenCreate.customerUserErrors
              .length === 0
          ) {
            data = {
              accessToken:
                res.data.data.customerAccessTokenCreate.customerAccessToken
                  .accessToken,
              messages: [],
            }
          } else {
            let messages =
              await res.data.data.customerAccessTokenCreate.customerUserErrors.map(
                error => {
                  return error.message
                },
              )
            data = {
              accessToken: null,
              messages: messages,
            }
          }
          resolve(data)
        })
    })

    return promise
  }

  const deleteAccessToken = async customerAccessToken => {
    let promise = new Promise(function (resolve, reject) {
      const deleteAccessTokenQuery = `mutation customerAccessTokenDelete($customerAccessToken: String!) {
              customerAccessTokenDelete(customerAccessToken: $customerAccessToken) {
                deletedAccessToken
                deletedCustomerAccessTokenId
                userErrors {
                  field
                  message
                }
              }
          }`

      axios({
        method: "post",
        url: `https://${domain}/api/${apiVersion}/graphql.json`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Shopify-Storefront-Access-Token": storefrontAccessToken,
        },
        data: {
          query: deleteAccessTokenQuery,
          variables: {
            customerAccessToken: customerAccessToken,
          },
        },
      })
        .catch(e => reject(e))
        .then(res => {
          resolve(res.data.data)
        })
    })

    return promise
  }

  const deleteAddress = async (addressId, customerAccessToken) => {
    let promise = new Promise(function (resolve, reject) {
      const deleteAddressQuery = `mutation customerAddressDelete($id: ID!, $customerAccessToken: String!) {
              customerAddressDelete(id: $id, customerAccessToken: $customerAccessToken) {
                customerUserErrors {
                  code
                  field
                  message
                }
                deletedCustomerAddressId
              }
          }`

      axios({
        method: "post",
        url: `https://${domain}/api/${apiVersion}/graphql.json`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Shopify-Storefront-Access-Token": storefrontAccessToken,
        },
        data: {
          query: deleteAddressQuery,
          variables: {
            id: addressId,
            customerAccessToken: customerAccessToken,
          },
        },
      })
        .catch(e => reject(e))
        .then(res => {
          resolve(res.data.data.customerAddressDelete)
        })
    })

    return promise
  }

  const updateAddress = async (addressId, address, customerAccessToken) => {
    let promise = new Promise(function (resolve, reject) {
      const updateAddressQuery = `mutation customerAddressUpdate($customerAccessToken: String!, $id: ID!, $address: MailingAddressInput!) {
              customerAddressUpdate(
                customerAccessToken: $customerAccessToken
                id: $id
                address: $address
              ) {
                customerAddress {
                  id
                }
                customerUserErrors {
                  code
                  field
                  message
                }
              }
          }`

      axios({
        method: "post",
        url: `https://${domain}/api/${apiVersion}/graphql.json`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Shopify-Storefront-Access-Token": storefrontAccessToken,
        },
        data: {
          query: updateAddressQuery,
          variables: {
            id: addressId,
            customerAccessToken: customerAccessToken,
            address: address,
          },
        },
      })
        .catch(e => reject(e))
        .then(async res => {
          let data
          if (
            res.data.data.customerAddressUpdate.customerUserErrors.length === 0
          ) {
            data = {
              id: res.data.data.customerAddressUpdate.customerAddress.id,
              messages: [],
            }
            resolve(data)
          } else {
            let messages =
              await res.data.data.customerAddressUpdate.customerUserErrors.map(
                error => {
                  return error.message
                },
              )
            data = {
              id: null,
              messages: messages,
            }
            resolve(data)
          }
        })
    })

    return promise
  }

  const addAddress = async (address, customerAccessToken) => {
    let promise = new Promise(function (resolve, reject) {
      const addAddressQuery = `mutation customerAddressCreate($customerAccessToken: String!, $address: MailingAddressInput!) {
              customerAddressCreate(
                customerAccessToken: $customerAccessToken
                address: $address
              ) {
                customerAddress {
                  id
                }
                customerUserErrors {
                  code
                  field
                  message
                }
              }
          }`

      axios({
        method: "post",
        url: `https://${domain}/api/${apiVersion}/graphql.json`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Shopify-Storefront-Access-Token": storefrontAccessToken,
        },
        data: {
          query: addAddressQuery,
          variables: {
            customerAccessToken: customerAccessToken,
            address: address,
          },
        },
      })
        .catch(e => reject(e))
        .then(async res => {
          let data
          if (
            res.data.data.customerAddressCreate.customerUserErrors.length === 0
          ) {
            data = {
              id: res.data.data.customerAddressCreate.customerAddress.id,
              messages: [],
            }
            resolve(data)
          } else {
            let messages =
              await res.data.data.customerAddressCreate.customerUserErrors.map(
                error => {
                  return error.message
                },
              )
            data = {
              id: null,
              messages: messages,
            }
            resolve(data)
          }
        })
    })

    return promise
  }

  const updateDefaultAddress = async (addressId, customerAccessToken) => {
    let promise = new Promise(function (resolve, reject) {
      const updateDefaultAddressQuery = `mutation customerDefaultAddressUpdate($customerAccessToken: String!, $addressId: ID!) {
              customerDefaultAddressUpdate(
                customerAccessToken: $customerAccessToken
                addressId: $addressId
              ) {
                customer {
                  id
                }
                customerUserErrors {
                  code
                  field
                  message
                }
              }
          }`

      axios({
        method: "post",
        url: `https://${domain}/api/${apiVersion}/graphql.json`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Shopify-Storefront-Access-Token": storefrontAccessToken,
        },
        data: {
          query: updateDefaultAddressQuery,
          variables: {
            addressId: addressId,
            customerAccessToken: customerAccessToken,
          },
        },
      })
        .catch(e => reject(e))
        .then(async res => {
          let data
          if (
            res.data.data.customerAddressCreate.customerUserErrors.length === 0
          ) {
            data = {
              id: res.data.data.customerAddressCreate.customerAddress.id,
              messages: [],
            }
            resolve(data)
          } else {
            let messages =
              await res.data.data.customerAddressCreate.customerUserErrors.map(
                error => {
                  return error.message
                },
              )
            data = {
              id: null,
              messages: messages,
            }
            resolve(data)
          }
        })
    })

    return promise
  }

  const resetPassword = async (resetUrl, password) => {
    let promise = new Promise(function (resolve, reject) {
      const resetPasswordQuery = `mutation customerResetByUrl($resetUrl: URL!, $password: String!) {
              customerResetByUrl(resetUrl: $resetUrl, password: $password) {
                customer {
                  id
                }
                customerAccessToken {
                  accessToken
                  expiresAt
                }
                customerUserErrors {
                  code
                  field
                  message
                }
              }
          }`

      axios({
        method: "post",
        url: `https://${domain}/api/${apiVersion}/graphql.json`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Shopify-Storefront-Access-Token": storefrontAccessToken,
        },
        data: {
          query: resetPasswordQuery,
          variables: {
            resetUrl: resetUrl,
            password: password,
          },
        },
      })
        .catch(e => reject(e))
        .then(async res => {
          let data
          if (
            res.data.data.customerResetByUrl.customerUserErrors.length === 0
          ) {
            data = {
              accessToken:
                res.data.data.customerResetByUrl.customerAccessToken
                  .accessToken,
              messages: [],
            }
          } else {
            let messages =
              await res.data.data.customerResetByUrl.customerUserErrors.map(
                error => {
                  return error.message
                },
              )
            data = {
              accessToken: null,
              messages: messages,
            }
          }
          resolve(data)
        })
    })

    return promise
  }

  const recoverPassword = async email => {
    let promise = new Promise(function (resolve, reject) {
      const recoverPasswordQuery = `mutation customerRecover($email: String!) {
              customerRecover(email: $email) {
                customerUserErrors {
                  code
                  field
                  message
                }
              }
          }`

      axios({
        method: "post",
        url: `https://${domain}/api/${apiVersion}/graphql.json`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Shopify-Storefront-Access-Token": storefrontAccessToken,
        },
        data: {
          query: recoverPasswordQuery,
          variables: {
            email: email,
          },
        },
      })
        .catch(e => reject(e))
        .then(async res => {
          let data
          let messages =
            await res.data.data.customerRecover.customerUserErrors.map(
              error => {
                return error.message
              },
            )
          data = {
            messages: messages,
          }
          resolve(data)
        })
    })

    return promise
  }

  const getProductRecommendations = async id => {
    let promise = new Promise(function (resolve, reject) {
      const getProductRecommendationsQuery = `query productRecommendations ($productId: ID!,$variantsFirst: Int = 50, $imagesFirst: Int = 10){
              productRecommendations (productId: $productId) {
                  description
                  handle
                  id
                  images(first: $imagesFirst) {
                      edges {
                          node {
                              originalSrc
                              altText
                          }
                      }
                  }
                  priceRange {
                      maxVariantPrice {
                      amount
                      }
                      minVariantPrice {
                      amount
                      }
                  }
                  options {
                      name
                      values
                  }
                  productType
                  tags
                  title
                  variants(first: $variantsFirst) {
                      edges {
                          node {
                              sku
                              availableForSale
                              id
                              selectedOptions {
                                  name
                                  value
                              }
                              compareAtPriceV2 {
                                  amount
                                  currencyCode
                              }
                              priceV2 {
                                  amount
                                  currencyCode
                              }
                              title
                              image {
                                  originalSrc
                                  altText
                              }
                          }
                      }
                  }
              }
          }`

      axios({
        method: "post",
        url: `https://${domain}/api/${apiVersion}/graphql.json`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Shopify-Storefront-Access-Token": storefrontAccessToken,
        },
        data: {
          query: getProductRecommendationsQuery,
          variables: {
            productId: id,
          },
        },
      })
        .catch(e => reject(e))
        .then(async res => {
          resolve(res)
        })
    })

    return promise
  }

  const getSellingPlans = async id => {
    let promise = new Promise(function (resolve, reject) {
      const getSellingPlanQuery = `query product ($productId: ID!){
        shopfiyStorefront {
              product (id: $productId) {
                  sellingPlanGroups(first: 20) {
                      edges {
                          node {
                              options {
                                  name
                                  values
                              }
                              name
                              sellingPlans(first: 50) {
                                  edges {
                                      node {
                                          id
                                          name
                                          options {
                                              name
                                              value
                                          }
                                          description
                                          priceAdjustments {
                                              adjustmentValue {
                                                  ... on SellingPlanFixedAmountPriceAdjustment {
                                                      adjustmentAmount {
                                                          amount
                                                          currencyCode
                                                      }
                                                  }
                                              }
                                          }
                                          recurringDeliveries
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
            }
          }`

      axios({
        method: "post",
        url: `https://${domain}/api/${apiVersion}/graphql.json`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Shopify-Storefront-Access-Token": storefrontAccessToken,
        },
        data: {
          query: getSellingPlanQuery,
          variables: {
            productId: id,
          },
        },
      })
        .catch(e => reject(e))
        .then(async res => {
          resolve(res)
        })
    })

    return promise
  }

  return {
    createCart,
    addLineItems,
    updateLineItems,
    removeLineItems,
    updateBuyerIdentity,
    updateCartDiscountCodes,
    getCustomer,
    createCustomer,
    getAccessToken,
    deleteAccessToken,
    deleteAddress,
    updateAddress,
    addAddress,
    updateDefaultAddress,
    resetPassword,
    recoverPassword,
    getProductRecommendations,
    getSellingPlans,
  }
}

const client = new ShopifyClient(
  process.env.GATSBY_MYSHOPIFY_URL,
  process.env.GATSBY_MYSHOPIFY_STOREFRONT_ACCESS_TOKEN,
  "2023-07",
)

export default function ShopifyWrapper(props) {
  const [isCartOpen, setIsCartOpen] = useState(false)
  const [cart, setCart] = useState({
    lines: [],
  })
  const [customer, setCustomer] = useState({
    addresses: [],
    orders: {
      edges: [],
    },
    tags: [],
  })

  useEffect(() => {
    let cart = window.sessionStorage.getItem("cart")
    if (cart != null && cart !== "undefined") {
      try {
        handleCheckout(JSON.parse(window.sessionStorage.getItem("cart")))
      } catch (error) {
        console.log(error)
      }
    } else {
      client.createCart({}).then(res => {
        handleCheckout(res)
      })
    }

    let accessToken = sessionStorage.getItem("accessToken")
    if (accessToken != null) {
      client.getCustomer(accessToken).then(res => {
        if (res.data && res.data.data && res.data.data.customer) {
          handleCustomer(res.data.data.customer)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setSessionStorage = res => {
    window.sessionStorage.setItem("cart", JSON.stringify(res))
  }

  const handleCustomer = res => {
    setCustomer(res)
    discounts.forEach(discount => {
      if (res?.tags.includes(discount.customerTag)) {
        client
          .updateCartDiscountCodes(cart.id, [discount.discountCode])
          .then(res => handleCheckout(res))
      }
    })
  }

  const resetCustomer = res => {
    let accessToken = sessionStorage.getItem("accessToken")
    client.getCustomer(accessToken).then(res => {
      if (res.data && res.data.data && res.data.data.customer) {
        console.log(res.data.data.customer)
        handleCustomer(res.data.data.customer)
      }
    })
  }

  const handleCheckout = res => {
    setCart(res)
    setSessionStorage(res)
  }

  const handleCart = res => {
    setIsCartOpen(res)
  }

  return (
    <>
      {React.cloneElement(props.children, {
        client: client,
        customer: customer,
        cart: cart,
        setCheckout: handleCheckout,
        setSessionStorage: setSessionStorage,
        setCart: handleCart,
        isCartOpen: isCartOpen,
        //Customer Methods
        resetCustomer: resetCustomer,
        createCustomer: client.createCustomer,
        getAccessToken: client.getAccessToken,
        deleteAccessToken: client.deleteAccessToken,
        deleteAddress: client.deleteAddress,
        updateAddress: client.updateAddress,
        addAddress: client.addAddress,
        updateDefaultAddress: client.updateDefaultAddress,
        resetPassword: client.resetPassword,
        recoverPassword: client.recoverPassword,
        getProductRecommendations: client.getProductRecommendations,
        getSellingPlans: client.getSellingPlans,
      })}
    </>
  )
}
