exports.discounts = [
  {
    customerTag: "Employee Pricing",
    discountCode: "Thrive Employee Discount",
  },
  {
    customerTag: "Loyal Customer Savings",
    discountCode: "LoyalCustomer",
  },
  {
    customerTag: "FULL COMP",
    discountCode: "FULL COMP",
  },
]
